import styles from "./ProfileDashboardNav.module.css";
import useApi from "lib/useApi";
import Avatar from "components/common/Avatar";
interface ProfileDashboardNavProps {
  // user?: any;
  onClick?: any;
  isOpen: boolean;
  userData?: any;
}

function ProfileDashboardNav({
  // user,
  onClick,
  isOpen,
  userData,
}: ProfileDashboardNavProps) {
  const { data: me } = useApi(`/api/users/my_user`, true, { immutable: true });
  const user = me;
  const firstName = user?.first_name || userData?.user?.first_name;
  const lastName = user?.last_name || userData?.user?.last_name;
  return (
    <a className={styles.container} onClick={onClick}>
      <Avatar
        imageUrl={user?.avatar_urls[0]}
        name={`${firstName} ${lastName}`}
        size="xs"
        showRing={true}
      />
    </a>
  );
}

export default ProfileDashboardNav;

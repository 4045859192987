import {
  Typography,
  TextField,
  Grid,
  Stack,
  Switch,
  styled,
  SelectChangeEvent,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import MainDialog from "components/dialog/MainDialog";
import postAPI from "lib/postApi";
import { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { useTimezoneSelect } from "react-timezone-select";
import { mutate } from "swr";
import { useRouter } from "next/navigation";
import { NumericFormat } from "react-number-format";
import { FontAwesomeIcon } from "helpers/helpers";
interface NewOrgDialogProps {
  open: boolean;
  handleClose?: any;
}

export default function NewOrgDialog({ open, handleClose }: NewOrgDialogProps) {
  const router = useRouter();
  const [form, setForm] = useState<any>({
    orgName: "",
    description: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    testOrg: true,
    maxRedemption: 10000,
    signUpBonus: false,
    signUpBonusAmount: "",
    timezone: "",
    orgTier: "",
  });

  useEffect(() => {
    setForm({
      orgName: "",
      description: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      testOrg: true,
      maxRedemption: 10000,
      signUpBonus: false,
      signUpBonusAmount: null,
      timezone: "",
      orgTier: "",
    });
  }, [handleClose]);

  const CustomSelect = (labelStyle) => {
    const { options, parseTimezone } = useTimezoneSelect({
      labelStyle,
    });

    const handleChange = (event: SelectChangeEvent) => {
      const value = event.target.value;
      const parsed = parseTimezone(value);
      setForm({ ...form, timezone: parsed.value });
    };

    return (
      <FormControl fullWidth>
        <InputLabel shrink={true}>Timezone</InputLabel>
        <Select
          notched={true}
          value={form.timezone}
          onChange={handleChange}
          fullWidth
          label="Timezone"
          size="small"
        >
          {options.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.value} | {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const createNewOrg = async () => {
    try {
      const response = await postAPI(
        `/api/orgs?organization[name]=${
          form.orgName
        }&organization[description]=${form.description}&organization[address]=${
          form.address
        }&organization[city]=${form.city}&organization[state]=${
          form.state
        }&organization[zip_code]=${form.zip}&organization[phone_number]=${
          form.phone
        }&organization[test]=${form.testOrg}&organization[max_redemption]=${
          form.maxRedemption
        }&organization[signup_bonus]=${
          form.signUpBonus
        }&organization[signup_bonus_amount]=${
          form.signUpBonusAmount * 100
        }&organization[time_zone]=${form.timezone}`,
        {}
      ).then(async (res) => {
        window.localStorage.removeItem("crowdSelected");
        
        window.localStorage.removeItem("orgSelected");
        window.localStorage.setItem("orgSelected", res?.id);
        mutate(`/api/orgs/my_orgs`);
        if (form.orgTier !== "" || form.orgTier !== undefined) {
          try {
            const response = await postAPI(
              `/api/orgs/${res?.id}/make_${form.orgTier}`,
              {}
            ).then((res) => {
              return response;
            });
          } catch (error) {
            console.error(error);
          }
        }
        setForm({
          orgName: "",
          description: "",
          address: "",
          city: "",
          state: "",
          zip: "",
          phone: "",
          testOrg: false,
          maxRedemption: 10000,
          signUpBonus: false,
          signUpBonusAmount: 0,
          timezone: "",
          orgTier: "",
        });
      }).finally(() => {
        router.refresh();
        handleClose();
      });
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const handleValueChange = (values: any) => {
    const { value } = values;
    const parsedValue = parseFloat(value);
    if (value) {
      setForm({ ...form, signUpBonusAmount: parsedValue });
    } else {
      // Handle the case when the input is cleared
      setForm({ ...form, signUpBonusAmount: "" });
    }
  };

  return (
    <MainDialog
      handleApprove={createNewOrg}
      approveText={
        form.orgName !== "" && form.description !== "" ? "Create" : null
      }
      handleClose={handleClose}
      open={open}
      closeText="Cancel"
      message={
        <Stack spacing={0}>
          <Stack spacing={1} direction="row" alignItems="center" mb={2} ml={1}>
            <FontAwesomeIcon icon="layer-plus" />
            <Typography variant="h6" mb={2}>
              Create a new organization
            </Typography>
          </Stack>

          <TextField
            autoFocus
            required
            value={form.orgName}
            onChange={(e) => setForm({ ...form, orgName: e.target.value })}
            margin="dense"
            id="name"
            label="Organization name"
            type="text"
            fullWidth
          />
          <TextField
            required
            value={form.description}
            onChange={(e) => setForm({ ...form, description: e.target.value })}
            margin="dense"
            multiline
            minRows={2}
            id="description"
            label="Description"
            type="text"
            fullWidth
          />
          <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
            <InputLabel shrink={true}>Subscription Level</InputLabel>
            <Select
              notched={true}
              fullWidth
              size="small"
              label="Subscription Level"
              value={form.orgTier}
              onChange={(e) => {
                setForm({ ...form, orgTier: e.target.value });
              }}
            >
              <MenuItem value="essentials">Essentials</MenuItem>
              <MenuItem value="pro">Pro</MenuItem>
              <MenuItem value="enterprise">Enterprise</MenuItem>
            </Select>
          </FormControl>

          {/* addresss */}
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                value={form.address}
                onChange={(e) => setForm({ ...form, address: e.target.value })}
                margin="dense"
                id="address"
                label="Address"
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                value={form.city}
                onChange={(e) => setForm({ ...form, city: e.target.value })}
                margin="dense"
                id="city"
                label="City"
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                value={form.state}
                onChange={(e) => setForm({ ...form, state: e.target.value })}
                margin="dense"
                id="state"
                label="State"
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                value={form.zip}
                onChange={(e) => setForm({ ...form, zip: e.target.value })}
                margin="dense"
                id="zip"
                label="Zip"
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <CustomSelect
                labelStyle={{
                  fontSize: "0.875rem",
                  color: "#000000",
                  fontWeight: 500,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputMask
                mask="(999)-999-9999"
                value={form.phone}
                onChange={(e) =>
                  setForm({ ...form, phone: e.target.value.replace(/\D/g, "") })
                }
                disabled={false}
                maskChar=" "
              >
                {() => (
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    size="small"
                    margin="dense"
                    label="Phone Number"
                    value={form.phone}
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={form.maxRedemption}
                onChange={(e) =>
                  setForm({ ...form, maxRedemption: e.target.value })
                }
                margin="dense"
                id="maxRedemption"
                label="Max redemption"
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
              >
                <CheckSwitch
                  color="primary"
                  checked={form.testOrg}
                  onChange={(e) =>
                    setForm({ ...form, testOrg: e.target.checked })
                  }
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography variant="caption">Test organization</Typography>
                <CheckSwitch
                  color="primary"
                  checked={form.signUpBonus}
                  onChange={(e) =>
                    setForm({ ...form, signUpBonus: e.target.checked })
                  }
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography variant="caption">Sign up bonus</Typography>
              </Stack>
            </Grid>
            {form.signUpBonus && (
              <NumericFormat
                fullWidth
                value={form.signUpBonusAmount}
                onValueChange={handleValueChange}
                customInput={TextField}
                autoFocus={true}
                allowNegative={false}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                allowLeadingZeros={false}
                prefix={"$"}
                label="Sign up bonus amount"
              />
            )}
          </Grid>
        </Stack>
      }
    />
  );
}

const CheckSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  //.css-1f22hqg-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
  "& .Mui-checked": {
    color: "#ffffff",
    // track
    "& + .MuiSwitch-track": {
      backgroundColor: "#0dd266",
      opacity: 1,
    },
  },
  "& .MuiSwitch-track": {
    background: "#cecece",
    opacity: 1,
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.dark)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

import { FontAwesomeIcon } from "helpers/helpers";
import React, { forwardRef } from "react";

interface AnimatedIconButtonProps {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  ariaLabel: string;
  icon: any;
  color?: string;
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

const AnimatedIconButton = forwardRef<
  HTMLButtonElement, 
  AnimatedIconButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>(({
  onClick,
  ariaLabel,
  icon,
  color,
  disabled,
  size = 'md',
  ...restProps
}, ref) => {
  const sizeClasses = {
    sm: 'w-6 h-6',
    md: 'w-10 h-10',
    lg: 'w-12 h-12',
  };

  const iconSizeClasses = {
    sm: 'text-sm',
    md: 'text-lg',
    lg: 'text-xl',
  };

  return (
    <button
      ref={ref}
      disabled={disabled}
      onClick={onClick}
      aria-label={ariaLabel}
      className={`relative border z-0 inline-flex items-center justify-center overflow-hidden rounded-full group bg-transparent hover:bg-purple-100 focus:outline-none focus:ring-2 focus:ring-pink-100 focus:ring-offset-2 transition-all duration-300 ease-in-out ${sizeClasses[size]}`}
      style={{ color: disabled ? "#ccc" : color ? color : "#4e19b0" }}
      {...restProps}
    >
      {!disabled && (
        <span
          style={{ backgroundColor: color ? color : "#4e19b0" }}
          className={`absolute w-0 h-0 transition-all duration-300 ease-out rounded-full group-hover:w-full group-hover:h-full `}
        ></span>
      )}
      <span className={`relative transition-colors duration-300 ease-in-out group-hover:text-white ${iconSizeClasses[size]}`}>
        {icon}
      </span>
    </button>
  );
});

AnimatedIconButton.displayName = "AnimatedIconButton";

export default AnimatedIconButton;
